import { useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useClickTracker } from 'tracking/ItemTracker';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import { Ratio, type UpsellFieldsFragment } from '@news/gql';
import { appendUtmTags } from '@news/tracking';

import { LazyImage } from 'components/LazyImage';
import { Typography } from 'components/Typography';
import { ButtonBase } from 'components/buttons';
import { BigArrow } from 'components/icons';
import { generateSrcSet } from 'lib/helpers';
import { roundedCardBorderRadius } from 'styles/mixins/borderRadius';
import { feedItemBoxShadow } from 'styles/mixins/boxShadow';
import { fifthElementBackground } from 'styles/mixins/fifthElementBackground';
import { theme } from 'styles/theme';

interface IProps {
  item: UpsellFieldsFragment;
}

const sizes = `(min-width: 1272px) 892px, (min-width: 767px) calc(75vw - 80px), calc(100vw - 24px)`;

export const Upsell = ({ item }: IProps) => {
  const { sendItemClickEvent } = useClickTracker();

  const isTV4PlayLink = new URL(item.linkUrl).hostname.endsWith('tv4play.se');
  const backgroundColor = item.backgroundColor || item.imageData.metadata?.hexColor || undefined;

  const srcSet = useMemo(
    () => generateSrcSet({ imageSrc: item.imageData.url, sizes: [894, 742, 560] }),
    [item.imageData]
  );

  return (
    <Container $backgroundColor={backgroundColor}>
      <UpsellImage srcSet={srcSet} sizes={sizes} src={item.imageData.url} alt="Upsell-bild" ratio={Ratio.Ratio_16x9} />
      {item.label && (
        <UpsellStyledLabel $isLive={item.label === 'Live'}>
          <UpsellLabelText variant="label">{item.label}</UpsellLabelText>
        </UpsellStyledLabel>
      )}
      <ContentContainer>
        {item.title && (
          <UpsellText as="h2" variant="upsell-title">
            {item.title}
          </UpsellText>
        )}
        {item.subtitle && (
          <UpsellText as="h3" variant="upsell-subtitle">
            {item.subtitle}
          </UpsellText>
        )}
        <UpsellButton
          as="a"
          onClick={sendItemClickEvent}
          href={appendUtmTags(item.linkUrl, 'Upsell', true)}
          $isTV4PlayLink={isTV4PlayLink}
          target="_blank"
        >
          <UpsellText variant="CTA-large">{item.linkText}</UpsellText>
          <UpsellArrow size={26} />
        </UpsellButton>
      </ContentContainer>
    </Container>
  );
};

const UPSELL_DESKTOP_HEIGHT = 280;

const Container = styled.div<{ $backgroundColor?: string }>`
  ${feedItemBoxShadow}
  ${roundedCardBorderRadius}
  margin: 0 ${sizeUnits[16]};
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ $backgroundColor }) => $backgroundColor || colors.black[50]};

  ${theme.mq.tablet} {
    height: ${UPSELL_DESKTOP_HEIGHT}px;
    margin: 0;
    justify-content: flex-end;
  }
`;

const ContentContainer = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${sizeUnits[16]};
  padding-top: 0;
  gap: ${sizeUnits[12]};
  ${theme.mq.tablet} {
    max-width: 380px;
  }
`;

const UpsellImage = styled(LazyImage)`
  mask-image: linear-gradient(${colors.black[100]} 80%, ${colors.black[10]} 97.5%, transparent 100%);
  ${theme.mq.tablet} {
    height: ${UPSELL_DESKTOP_HEIGHT}px;
    position: absolute;
    right: 0;
    mask-image: linear-gradient(90deg, transparent 1%, ${colors.black[10]} 10%, black 40%);
  }
`;

const UpsellText = styled(Typography)`
  color: ${colors.white[100]};
`;

const UpsellButton = styled(ButtonBase)<{ $isTV4PlayLink: boolean }>`
  display: flex;
  gap: ${sizeUnits[8]};
  width: 100%;
  background-color: ${colors.red.tv4};
  ${({ $isTV4PlayLink }) => $isTV4PlayLink && fifthElementBackground({ backgroundWidth: 600 })};
  ${theme.mq.tablet} {
    width: fit-content;
    padding: ${sizeUnits[8]} ${sizeUnits[24]};
  }
`;

const UpsellArrow = styled(BigArrow)`
  color: ${colors.white[100]};
`;

const UpsellStyledLabel = styled.div<{ $isLive: boolean }>`
  overflow: hidden;
  position: absolute;
  top: ${sizeUnits[8]};
  left: ${sizeUnits[8]};
  border-radius: 6px;
  padding: 6px ${sizeUnits[8]};
  background-color: ${colors.red.tv4Light};
  display: flex;
  flex-direction: row;
  gap: ${sizeUnits[4]};
  align-items: center;
  ${({ $isLive }) => $isLive && upsellLiveLabelDot}

  ${theme.mq.tablet} {
    top: ${sizeUnits[16]};
    left: ${sizeUnits[16]};
  }
`;

const upsellLiveLabelDotBlink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const upsellLiveLabelDot = css`
  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${colors.white[100]};
    animation: ${upsellLiveLabelDotBlink} 1.5s infinite;
  }
`;

const UpsellLabelText = styled(Typography)`
  color: ${colors.white[100]};
  position: relative;
`;
